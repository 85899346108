//import React from 'react';

// todo:
// 1. determine if scores need to be calculated (by comparing current scores and the local player's data)
// 2. return null as early as possible if scores dont need recalc
// 3. for each set of results
//   a. assign points for each response to the player who made that response
//   b. assign points to each player for the delta of their votes versus the total votes
//   c. maybe add bonuses for filling out all responses, sending all votes
// 4. return all this as an object with keys for each player userid, of the form userid: [{answers: score, votes: score, bonus: score}, ...]

const ANS_SCORE = 1500;
const ACC_SCORE = 1000;
const BON_SCORE = 500; // make sure this matches VoteForm!

const ScoreCalc = function(gd, scores) {
	
	//console.log("CALC SCORES:");
	//console.log(gd);
	//console.log(scores);

	
	function calcPct(resultObj) {
		return resultObj.w / Math.max(1, resultObj.w + resultObj.l);
	}


	// score = (percent_voted * BON_SCORE)
	function calcRoundBonus(uid, roundIx) {
		const userVotes = gd.results[roundIx][uid];
		if(!userVotes)
			return 0;
		let votes = 0;
		for(let i=0; i<userVotes.length; i++)
			votes += userVotes[i].w;
		const entryCount = gd.entries[roundIx].length;
		const votePairs = Math.max(1, (entryCount * (entryCount - 1)) / 2);
		return Math.round(BON_SCORE * (votes / votePairs));
	}
	
	// score = Sum(my_answers, (my_answer.win_pct / 2) * ANS_SCORE)
	function calcAnswerScore(uid, roundIx) {
		
		//console.log("CALC ENTS: " + roundIx);
		//console.log(gd.entries);
		
		const rEnts = gd.entries[roundIx];
		const rTots = gd.results[roundIx].total;
		let total = 0;
		for(let i=0; i<rEnts.length; i++) {
			if(rEnts[i].userid == uid) {
				for(let j=0; j<rTots.length; j++) {
					if(rTots[j].r == rEnts[i].resp)
						total += rTots[j].w / (rTots[j].w + rTots[j].l);
				}
			}
		}
		return Math.round(ANS_SCORE * (total / (2 * Math.max(1, rEnts.length))));
	}

	// score = Sum([my_votes, total_votes], (1 - delta(my_vote, total_vote)) * ACC_SCORE / total_entries)
	function calcVoteScore(uid, roundIx) {
		const rTots = gd.results[roundIx]['total'];		
		const rUser = gd.results[roundIx][uid];	
		if(!rUser)
			return 0;
		let total = 0;
		for(let i=0; i<rTots.length; i++)
			for(let j=0; j<rUser.length; j++)
				if(rUser[j].r == rTots[i].r)
					total += 1 - Math.abs(calcPct(rUser[j]) - calcPct(rTots[i]));
		return Math.round((ACC_SCORE / rTots.length) * total);
	}

	
	let modified = false;
	if(!scores) {
		scores = {};
		modified = true;
	}
	if(gd && gd.p && gd.results) {
		const rounds = gd.results.length;
		for(let i=0; i<gd.p.length; i++) {
			const uid = gd.p[i].userid;
			if(!scores[uid] || scores[uid].length != rounds) { 
				modified = true;
				scores[uid] = [];
				for(let j=0; j<rounds; j++) {
					scores[uid][j] = {answers: calcAnswerScore(uid, j), votes: calcVoteScore(uid, j), bonus: calcRoundBonus(uid, j)};
				}
			} 
			else {
				for(let j=0; j<rounds; j++) {
					const newScores = {answers: calcAnswerScore(uid, j), votes: calcVoteScore(uid, j), bonus: calcRoundBonus(uid, j)};
					if(scores[uid][j].answers != newScores.answers || scores[uid][j].votes != newScores.votes || scores[uid][j].bonus != newScores.bonus) {
						modified = true;
						scores[uid][j] = newScores;
					}
				}
			}
		}
	}
	if(!modified)
		return null;
	else
		return scores;	
};

export default ScoreCalc;

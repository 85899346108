import React from 'react';
import { StyleSheet, Text, View } from 'react-native';


export function PlayersDisplay(props){

	let players = props.players || null;
	const myID = props.localId || null;
	const scores = props.scores || {};
	
	//console.log("scores");
	//console.log(scores);
	
	if(!players)
		return null;
	if(scores) {
		players = [...players];
		players.sort((a,b) => {
			const aScore = scores[a.userid] || 0;
			const bScore = scores[b.userid] || 0;
			return bScore - aScore; // inverted sort, from highest to lowest
		});
		
	}
	
	function calcTotalScore(scoreObj) {
		if(!scoreObj || !scoreObj.length)
			return 0;
		let tot = 0;
		for(let i=0; i<scoreObj.length; i++)
			if(!!scoreObj[i])
			{
				tot += scoreObj[i].answers;
				tot += scoreObj[i].votes;
				tot += scoreObj[i].bonus;
			}
		return tot;
	}
	
	const playerConts = [];
	for(let i=0; i<players.length; i++) {
		playerConts[i] = (
			<View style={styles.playerCont} key={"pl-"+i}>
				<Text style={styles.nameText}>{players[i % players.length].name}</Text>
				<Text style={styles.spacer}>:</Text>
				<Text style={styles.pointText}>{(!scores || !scores[players[i % players.length].userid]) ? 0 : calcTotalScore(scores[players[i % players.length].userid])} pts</Text>
			</View>
		);
	}
	
	return (
		<View style={styles.container}>
			{playerConts}
		</View>	
	);
}

const styles = StyleSheet.create({
	container:{
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		width: '100%'
	},
	playerCont: {
		alignItems: 'center',
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: '#ddd',
		padding: 3,
		margin: 1,
		flexDirection: 'row'
	},
	nameText:{
		fontSize: 14,
	},
	pointText:{
		fontSize: 16,
		color: "#115"
	},
	spacer:{
		marginLeft: 0,
		marginRight: 8,
		fontSize: 15
	}
});
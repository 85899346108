import React, {useState} from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View, Animated, Easing } from 'react-native';

import {UniBar} from './UniBar';

export function ResponseView(props) {
	const [resp, setResp] = useState([]);
	const [respAccept, setRespAccept] = useState([]);
	const [curQuestion, setCurQuestion] = useState("");
	const [respShown, setRespShown] = useState(1);
	const animVal = React.useRef(new Animated.Value(0)).current;

	if(!!props.disabled || !props.question) {
		return null;
	}
	
	if(props.question && props.question != curQuestion) {
		setResp([]);
		setRespAccept([]);
		setRespShown(1);
		setCurQuestion(props.question);
	}
	if(resp.length != props.count)
	{
		setResp(new Array(props.count));
		setRespAccept(new Array(props.count));
		return null;
	}

	const boxAnimInterp =  animVal.interpolate({
		inputRange: [0, 0.7, 1],
		outputRange:["rgb(255,255,255)" , "rgb(255,200,200)", "rgb(255,50,50)"]
	});	
	const doFadeAnim = (anim) => {
		anim.setValue(1);
		Animated.timing(anim, {
			fromValue: 1,
			toValue: 0,
			duration: 1200
		}).start();
	};
	
	let boxes = [];
	let allDone = !!(respShown >= props.count);
	let acceptCount = 0;
	for(let i=0; i<props.count && i<respShown; i++)
	{
		const ix = i;
		const r = resp[ix] || "";
		allDone &= respAccept[i];
		if(respAccept[i]) acceptCount++;
		let sty = styles.inpBox;
		if(respAccept[ix]) sty = StyleSheet.compose(styles.inpBox, {backgroundColor: '#DDD'});
		boxes.push( 
			<View key={"resp-"+ix}>
				<Animated.View style={(r.length < 3 && ix < (respShown - 1)) ? ({backgroundColor: boxAnimInterp}) : ({})}>
					<TextInput 
						style={sty}
						editable={!respAccept[ix]}
						maxLength={90}
						placeholder="Enter a response"
						onChangeText={txt =>{ resp[ix] = txt; setResp([...resp]); }}
						key={ix+""}
						value={r.length < 50 ? r : r.substring(0,50)}
					/> 
				</Animated.View>
			</View>
		);
	}
		
	const sec = Math.ceil(props.time || 5);
	
	return (
	<View>
		<Text style={{fontSize: 24, padding: 8, textAlign: 'center', width: 380, color: "#184", backgroundColor: "#eee"}}>
			{props.question}
		</Text>
		<View style={styles.headerInfo}>
			<Text style={{margin: 20, fontSize: 16}}>Please provide your best answer{resp.length < 2 ? "" : "s"} below {resp.length < 2 ? "" : ("(" + acceptCount + " / " + resp.length + ")")}:</Text>
			<UniBar fill={props.time / 60} width={200} height={25} label="Time remaining" />
		</View>
		{boxes}
		<Button 
			title={allDone ? "Please Wait" : "Submit Answers"}
			disabled={!!allDone}
			onPress={() => {
				setRespShown(respShown+1);
				let ok = true;
				for(let i=0;i<resp.length; i++) {
					let ix = i;
					if(respAccept[i]) continue;
					else if(!resp[i] || resp[i].length < 3) ok = false;
					else if(props.onSubmit)
					{
						respAccept[ix] = true;
						setRespAccept([...respAccept]);
						props.onSubmit(resp[i], (wasOk) => {
							console.log("ix="+ix+", wasOk="+wasOk);
							if(wasOk) {
								respAccept[ix] = true;
								setRespAccept([...respAccept]);
							} else {
								resp[ix] = "";
								setResp([...resp]);
								respAccept[ix] = false;
								setRespAccept([...respAccept]);
								doFadeAnim(animVal);				
							}
						});		
					}
				}
				if(!ok)
					doFadeAnim(animVal);				
			}}
		/>
	</View>
	);

}

const styles = StyleSheet.create({
	inpBox: {
		height: 40, 
		borderWidth: 1, 
		padding: 5, 
		fontSize: 20
	},
	headerInfo: {
		alignItems: 'center',
		marginBottom: 5
	}
});
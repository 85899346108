import React from 'react';
import { StyleSheet, Text, View } from 'react-native';


export function UniBar(props){

	const fill = (!props.fill && props.fill !== 0) ? null : Math.min(1, Math.max(0, props.fill));
	const fillColor = !props.fillColor ? null : props.fillColor;
	const bgColor = !props.bgColor ? null : props.bgColor;
	const width = !props.width ? null : props.width;
	const height = !props.height ? null : props.height;
	const label = props.label || "";

	let outSty = sty.outerBar;
	let inSty = sty.innerBar;
	
	if(width !== null || height !== null || bgColor !== null) {
		const overrides = {};
		if(width) overrides.width = width;
		if(height) overrides.height = height;
		if(bgColor) overrides.backgroundColor = bgColor;
		outSty = StyleSheet.compose(outSty, overrides);		
	}
	if(fill !== null || fillColor !== null) {
		const overrides = {};
		if(fill !== null) overrides.width = Math.floor((1000 * fill) / 10)+"%";
		if(fillColor) overrides.backgroundColor = fillColor;
		inSty = StyleSheet.compose(inSty, overrides);		
	}
	
	
	return (
		<View style={outSty}>
			<View style={inSty} />
			<View style={sty.labelCont}>
				<Text style={sty.labelText}>{label}</Text>
			</View>
		</View>	
	);
}

const sty = StyleSheet.create({
	outerBar: {
		width: 120,
		height: 12,
		borderWidth: 1,
		backgroundColor: '#fff'
	},
	innerBar: {
		width: '42%',
		height: '100%',
		backgroundColor: '#444'
	},
	labelCont: {
		justifyContent:'center',
		alignItems:'center',
		alignSelf:'center', 
		position:'absolute'
	},
	labelText:{
		color: "#aaa",
		textShadowRadius: 2,
		textShadowColor: "#111",
	}

});

import React, {useState} from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View, Animated, Easing } from 'react-native';

import {UniBar} from './UniBar';

// make sure this matches ScoreCalc!
const BON_SCORE = 500;

export function VoteForm(props) {
	const [processing, setProcessing] = useState(false);
	if(props.disabled)
		return null;
	const question = props.prompt || "Question?";
	const choiceA = (props.choices && props.choices.length) ? props.choices[0] : "Error";
	const choiceB = (props.choices && props.choices.length > 1) ? props.choices[1] : "Error";
	const chooseCallback = props.onChoose || ((choice, cb) => { console.log("Chose " + choice+", but no handler was set in props.onChoose"); cb(); });
	const remainingCount = props.remainingCount || 0;
	const totalCount = props.totalCount || 1;
	const remainingTime = Math.floor(props.time || 0);


	function handleVote(isChoiceA) {
		const winner = isChoiceA ? choiceA : choiceB;
		setProcessing(true);
		chooseCallback(winner, () => setProcessing(false));
	};
	
	const pctComplete = 1 - remainingCount / totalCount;
	
	return (
		<View style={styles.container}>
			<Text style={styles.bighead}>{question}</Text>
			<Text style={styles.importantText}>Time to vote! Think fast! ({remainingCount} Votes Remain)</Text>
			<View style={styles.barCont}>
				<UniBar fill={1 - pctComplete} width={200} height={25} label={Math.round(BON_SCORE * pctComplete) +" Bonus Points Earned"} />
				<UniBar fill={remainingTime / 120} width={200} height={25} label={"Time Until Polls Close"} />
			</View>
			{remainingCount <= 0 
			? (<Text>Youve made your choices! Please wait...</Text>)
			: (<View style={styles.formArea}>
				<View style={styles.formCont}>
					<Text style={choiceA.length > 28 ? styles.smallhead : styles.bighead}>
						{choiceA}
					</Text>	
					<Text style={{width: "100%"}} />
					<Text style={styles.spacey}>
						Is this the better answer?
					</Text>		
					<View style={{width: "90%"}}>
						<Button title="Vote!" disabled={processing} onPress={() => handleVote(true)} />			
					</View>
				</View>	
				<View style={styles.formCont}>
					<Text style={choiceB.length > 28 ? styles.smallhead : styles.bighead}>
						{choiceB}
					</Text>	
					<Text style={{width: "100%"}} />
					<Text style={styles.spacey}>
						Is this the better answer?
					</Text>		
					<View style={{width: "90%"}}>
						<Button title="Vote!" disabled={processing} onPress={() => handleVote(false)} />			
					</View>
				</View>					
			</View>)}
		</View>
	);

}



const styles = StyleSheet.create({
   container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  barCont: {
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: 20
  },
  formCont: {
	flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
	width: 190,
	margin: 2
  },
  formArea: {
	  flex: 1, 
	  flexShrink: 0, 
	  flexGrow: 0, 
	  flexBasis: 150, 
	  flexDirection: 'row'
  },
  bighead: {
	  fontSize: 26
  },
  smallhead: {
	  fontSize: 18
  },
  divider:{
	  height: 2,
	  margin: 10,
	  width: "40%",
	  backgroundColor: "#444"
  },
  importantText: {
	  fontSize: 16,
	  margin: 3
  },
  spacey: {
	margin: 20  
  },
  fixToText: {
	width: "90%",
	flex: 1,	
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  outerBar: {
	width: 80,
	height: 12,
	borderWidth: 1,
	marginRight: 10
  }
  
});

import React, {useState} from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View, Animated, Easing } from 'react-native';

const TESTALLOW1P = __DEV__;

export function LobbyView(props) {
	
	const [dots, setDots] = useState(0);
	
	if(props.disabled)
		return null;
	const gd = props.data;
	const renderResult = (item) => {
		return(<View><Text style={{fontSize:20}}>{(1+item.index) + ": " + item.item.name}</Text></View>); 
	};
	
	const sec = Math.floor(Date.now() / 2000);
	if(sec != dots) setDots(sec);
	let dotStr = "";
	for(let i=0; i<(sec%4); i++) dotStr += ".";
	
	return (
		<View style={{alignItems:"center"}}>
			<Text style={{fontSize: 24, margin: 10}}>Waiting for Players{dotStr}</Text>
			<FlatList data={gd.p} renderItem={renderResult} keyExtractor={(item,index) => index.toString()}/>
			
			<Text style={{fontSize: 24, margin: 10}}>When Everyone is Here...</Text>
			<Button 
				title="Click to Play" 
				disabled={!TESTALLOW1P && gd.p.length < 2} 
				onPress={props.onStart} 
			/>
		</View>	
	);
}
import React, {useState, useRef, useEffect} from 'react';
import { NativeModules, StyleSheet, Text, View, Button } from 'react-native';
//import { AppLoading } from 'expo';
import { useFonts } from '@use-expo/font';

import Request from './code/Request';
import ScoreCalc from './code/ScoreCalc';
import {LoginComponent} from './code/LoginComponent';
import {HeaderText} from './code/HeaderText';
import {PlayersDisplay} from './code/PlayersDisplay';
import {LobbyView} from './code/LobbyView';
import {ResponseView} from './code/ResponseView';
import {VoteForm} from './code/VoteForm';
import {ResultsDisplay} from './code/ResultsDisplay';
import {HowToPlayControl} from './code/HowToPlayControl';

const UidOverride = "over"+"ride";

const myQs = [
"What should be commemorated with a statue?",
"What is the best reason to start a war?",
"What helps get through the day?",
"What smells best?", 
"Who is always there for you?",
"What is the weirdest superpower?",
"What do you want to hear?",
"What is a great game?",
"What is the most tasty drink?",
"What is the best excuse for not turning in your homework?",
"What is a great souvenir to bring back from vacation?",
"What should Santa give bad kids instead of coal?",
"What makes you laugh without fail?", 
"Who is the fairest of them all?", 
"What's the best new fad diet?", 
"What is the cutest?", 
"Where do you want to go the most?",
"What is the most cringe-worthy?", 
"What's the best thing to bring to a party?", 
"What do you need to become President?",
"Where do you go for the best time?", 
"If time travel were real, who is the best person to travel to meet?", 
"Who is the worst?", 
"Why keeps you up at night?",
];
const age18Qs = [
"What turns you on most?",
"What should guys think about to last longer in bed?",
"What is a relationship dealbreaker?",
"Where would you wish to lose your virginity?",
"What never fails to get you laid?", 
"What's the most classy euphemism for a penis?",
];
const myntQs = [
"What's the worst thing to find in your Kung Pao chicken?",
"What is *really* a girl's best friend?", 
"What's the perfect addition to a romantic dinner?", 
"What are my parents hiding from me?", 
"What gets better with age?", 
"What is there a lot of in heaven?", 
];

const StarterQs = [...myQs, ...age18Qs, ...myntQs];


export default function App() {
	const [loaded] = useFonts({"roboto-mono": require('./assets/fonts/Roboto-Mono.ttf')});	
	const [gd, setGd] = useState(
		(UidOverride=="override" && localStorage.getItem("UID") !== undefined && localStorage.getItem("UID") != "undefined") 
		? {"id": localStorage.getItem("UID")} 
		: {});
	const [error, setError] = useState("");
	const [scores, setScores] = useState({}); //cached, calculated scores (todo)
	const [updTO, setUpdTO] = useState(0);
	const [isUpdating, setIsUpdating] = useState(false);
	const [isUpdFrame, setIsUpdFrame] = useState(false);
	const [votePairs, setVotePairs] = useState([]);
	const [curPairIx, setCurPairIx] = useState(0);
	const [sentNextQ, setSentNextQ] = useState(0);
	
	//console.log("gd at refresh:");
	//console.log(gd);
	
	// logic helper functions
	function handleStart(pname, code) {
		console.log("gd at handleStart");
		console.log(gd);
		if(code == null)
			new Request("create.php?n="+pname+(UidOverride=="override" && gd.id ? "&uid="+gd.id : ""), (json)=> { 
				if(json.error) { setGd({...gd, code: ""}); setError(json.error); } 
				else {
					console.log("create json");
					console.log(json);
					new Request("update.php?c="+json.game.code+(UidOverride=="override" ? "&uid="+json.id : ""), (json)=>{
						if(json.error) { setGd({...gd, code: ""}); setError(json.error); } 
						else {
							if(UidOverride == "override") localStorage.setItem("UID", json.id);
							setGd(json);
						}
					});
					setGd(json);
					setError("");
					console.log("json before 1st upd create:");
					console.log(json);
					setUpdTO(setTimeout(()=>{
						handleUpdate(json);
					}, 200));
				}
			});
		else {
			setGd({game:{code: code}});
			new Request("update.php?n="+pname+"&c="+code+(UidOverride=="override" && gd.id ? "&uid="+gd.id : ""), (json)=>{ 
				if(json.error) { setGd({...gd, code: ""}); setError(json.error); } 
				else {
					if(UidOverride == "override") localStorage.setItem("UID", json.id);
					setGd(json);
					setError("");
					
					console.log("json before 1st upd join:");
					console.log(json);
					setUpdTO(setTimeout(()=>{
						handleUpdate(json);
					}, 200));
				}
			});
		}
	}

	
	function handleUpdate(gdref) {
		let entriesIndex = 0;
		if(gdref && gdref.game && gdref.game.q1 && gdref.game.q1.length > 0 && gdref.game.timerResp <= 0) {
			if(!gdref.entries || gdref.entries.length < 1) entriesIndex = 1;
			else if(gdref.game.q2.length > 0 && gdref.entries.length < 2) entriesIndex = 2;
			else if(gdref.game.q3.length > 0 && gdref.entries.length < 3) entriesIndex = 3; 
		}
		const uidStr = (UidOverride=="override" && gdref.id ? "&uid="+gdref.id : "");
		console.log("gdref before upd:");
		console.log(gdref);
		const voteTime = (gdref && gdref.game) ? gdref.game.timerVote : 0;
		new Request("update.php?c="+gdref.game.code+uidStr+(entriesIndex ? "&e=1" : ""), (json)=> {
			if(json.error) setError(json.error);
			else { 
				/*console.log("gdref and json after update...");
				console.log("gdref and json after update...");
				console.log(gdref);
				console.log(json+"");
				console.log(JSON.stringify(json));
				console.log(json);
				console.log("gdref and json above");
				console.log("gdref and json above");*/

				setGd(gdref = {...gd, ...json}); 
				setError("");
				
				if(entriesIndex) {
					const ent = json.entries[entriesIndex-1]; // entries are now objects, eg {userid: UID, resp: entryStr}
					const ec = ent.length;
					const newChoices = [];
					for(let i=0; i<ec; i++)
						for(let j=i+1; j<ec; j++)
						{
							console.log("pushing " + ent[i] + ", " + ent[j]);
							newChoices.push(Math.random() < 0.5 ? [ent[i].resp,ent[j].resp] : [ent[j].resp,ent[i].resp]);
						}
					setVotePairs(newChoices);
					setCurPairIx(Math.floor(Math.random() * newChoices.length));
				}
			}
			setIsUpdating(false);
			if(voteTime > 0 && gdref.game.timerVote <= 0)
				handleGetResults(gdref);
		});
	}

	
	function handleSubmission(question, response, callback) {
		if(!gd || !gd.game) {
			setError("State was mismanaged, cannot submit.");
			return;
		}
		if(!question && !response) {
			while(!question) {
				question = StarterQs[Math.floor(Math.random() * StarterQs.length)];
				if(gd.game.q1 == question) question = null;
				else if(gd.game.q2 == question) question = null;
			}
		}
		const t = !question ? "r" : "q";
		const s = (t == "q" ? question : response);
		const uidStr = (UidOverride=="override" && gd.id ? "&uid="+gd.id : "");
		// params: c=code, s=data, t=(q|r)
		new Request("submit.php?c="+gd.game.code+uidStr+"&t="+t+"&s="+s, (json) => {
			let succ = 0;
			if(json.error) setError(json.error);
			else if(json.success == 0) setError("Input was rejected");
			else { setError(""); succ = 1; }
			if(callback)callback(succ);
		});
	}
	
	// entry.php?ds=codeX&w=winner&l=loser   returns: {'success': 0/1} (0 for db fail only!)
	// results.php?ds=codeX[&n=UID]          returns: [{'r': 'respStr', 'w': winCount, 'l': lossCount}, {'r': 'respStr', 'w': winCount, 'l': lossCount}, ...]
	// note for conversion, previously
	// entry returned result:ok and count:votes, but these aren't used so output is minified
	// results returned same data but keys were 'name', 'wins', 'losses', which have been minified
	
	function handleVote(choiceResponse, callback) {
		if(!gd || !gd.game || !gd.game.code) {
			setError("State was mismanaged, cannot vote.");
			return;
		}		
		const cur = (curPairIx >= 0 && curPairIx < votePairs.length) ? votePairs[curPairIx] : null;
		if(!cur || cur.length < 2) {
			setError("Choices not found, cannot vote.");
			callback();
			return;			
		}
		const isChoice0 = choiceResponse == cur[0];
		const isChoice1 = choiceResponse == cur[1] && !isChoice0;
		if(!isChoice0 && !isChoice1) {
			setError("Vote for '"+choiceResponse+"' not found in choices "+JSON.stringify(cur));
			callback();
			return;			
		}
		const qNum = gd.game.q3 ? 3 : (gd.game.q2 ? 2 : (gd.game.q1 ? 1 : 0));
		if(qNum <= 0) {
			setError("No question found for vote!");
			callback();
			return;
		}
		
		const codeDs = gd.game.code + qNum;
		const uidStr = (UidOverride=="override" && gd.id ? "&uid="+gd.id : "");
		const win = isChoice0 ? cur[0] : cur[1];
		const lose = isChoice0 ? cur[1] : cur[0];
		new Request("entry.php?ds="+codeDs+uidStr+"&w="+win+"&l="+lose, (json) => {
			let succ = 0;
			if(json.error) setError(json.error);
			else if(json.success == 0) setError("Unexpected DB error!");
			else { setError(""); succ = 1; }
			votePairs.splice(curPairIx, 1);
			setCurPairIx(Math.floor(Math.random() * votePairs.length));		
			setVotePairs([...votePairs]);
			callback();
		});
	}

	// Notes to keep my head together...
	// results will be a big data structure:
	// { id: myUID, game: gameData, entries:[[entries1],...], results: ??? }
	// ??? = [R1, R2, R3] where Rx = { playerUID: [results list], total: [results list] }
	// scores will be populated from this data (dynamic, or cached?)

	function handleGetResults(gdref) {
		if(!gdref || !gdref.game || !gdref.game.code || !gdref.game.q1) {
			setError("State was mismanaged, cannot request results.");
			return;
		}
		if(gdref.game.timerVote > 0) {
			setError("Timing error, too early to get results.");
			return;
		}
		const qNum = gdref.game.q3 ? 3 : (gdref.game.q2 ? 2 : (gdref.game.q1 ? 1 : 0));
		if(gdref.results && gdref.results.length >= qNum) {
			setError("We already have these results.");
			return;
		}
		
		const uidStr = (UidOverride=="override" && gdref.id ? "&uid="+gdref.id : "");
		const urlStub = "results.php?ds="+(gdref.game.code+qNum)+uidStr;
		new Request(urlStub, (json) => {
			if(json.error) {
				if(json.error == error) setError("Double fail. IDK what to do now...");
				else {
					setError(json.error);
					handleGetResults(gdref);
					return;
				}
			}
			//json should be an array of results of the form: [{'r': 'respStr', 'w': winCount, 'l': lossCount}, {'r': 'respStr', 'w': winCount, 'l': lossCount}, ...]
			// idk what's newer... gd or gdref? they were readonly until now... i guess lets lock it into gd and hope it works
			const gdnew = {...gdref};
			gdnew.results = (gdnew.results || []);
			gdnew.results[qNum-1] = {total: json};		
			setGd(gdnew);
			// next get MY results
			new Request(urlStub+"&n="+gdnew.id, (json) => {
				if(json.error == error) setError("Failed to get my stats."); 
				else {
					gdnew.results[qNum-1] = {...(gdnew.results[qNum-1]), [gdnew.id]: json};
					setGd({...gdnew});
				}
				// finally, get everyone's results
				for(let i=0; i<gdnew.p.length; i++) {
					const id = gdnew.p[i].userid;
					if(id == gdnew.id)
						continue;
					new Request(urlStub+"&n="+id, (json) => {
						if(json.error == error) setError("Failed to get another player's stats."); 
						else {
							gdnew.results[qNum-1] = {...(gdnew.results[qNum-1]), [id]: json};
							setGd({...gdnew});
						}
					});
				}
			});
		});
	}
	
	
	// set up refresh cycle with setTimeout
	if(gd && gd.game) {
		if(!isUpdating) {		
			const nextUpdate = 15 + 1000 * (gd.game.timerResp > 0 ? gd.game.timerResp : (gd.game.timerVote > 0 ? gd.game.timerVote : 5));		
			//console.log("gd before setTO:");
			//console.log(gd);
			if(updTO > 0) clearTimeout(updTO);
			setIsUpdating(true);
			setIsUpdFrame(false);
			setUpdTO(setTimeout(()=>{
				setIsUpdFrame(true);
			}, Math.min(nextUpdate, 2200)));
		} else if(isUpdFrame) {
			// TODO: figure out when game is "over" and stop updating!
			handleUpdate(gd);
			setIsUpdFrame(false);
		}
	}
	
	
	// calculations needed prior to rendering
	let myName = null, myScore = 0;
	if(gd.p && gd.id)
	{
		for(let i=0;i<gd.p.length; i++)
			if(gd.p[i].userid == gd.id)
			{
				myName = gd.p[i].name;
				break;
			}
		if(scores) myScore = scores[gd.id] || 0;
	}
	
	const responseCounts = [5, 5, 5, 4, 3, 2, 2];
	const responsesPerPlayer = (!gd || !gd.p || gd.p.length >= responseCounts.length) ? 1 : responseCounts[gd.p.length];	
	const latestQuestion = (!gd || !gd.game) ? "N/A" : (gd.game.q3 || gd.game.q2 || gd.game.q1);
	
	const currentChoices = (curPairIx >= 0 && curPairIx < votePairs.length) ? votePairs[curPairIx] : null;
	const roundEntryCount = (!gd || !gd.entries || !gd.entries.length) ? 0 : gd.entries[gd.entries.length - 1].length;
	
	const timeSinceQuestion = (!gd || !gd.game || !gd.game.curTime) ? 0 : (gd.game.curTime - gd.game.timer);
	
	// send next question when the time comes
	if(gd && gd.game && gd.p && gd.p.length > 0 && gd.game.q1) {
		if(gd.game.timerVote > 0) {
			if(sentNextQ > 0)
				setSentNextQ(0);
		}
		else if(sentNextQ == 0){
			let ix = -1;
			for(let i=0; ix<0 && i<gd.p.length; i++)
				if(gd.p[i] && gd.p[i].userid == gd.id)
					ix = i;
			if(ix >= 0) {
				const myDelay = ix * 5; // so everyone isn't sending q's at once, we limit to 5s * playerIndex
				if(timeSinceQuestion > (210 + myDelay)) {
					handleSubmission(null,null,null);
					setSentNextQ(1);
				}
			}
		}
	}
	
	// update scores as needed
	const newScores = (ScoreCalc(gd, scores));
	if(!!newScores) setScores(newScores);
	
	if(!loaded)
		//return ( <AppLoading />	);
		return null;
	else return (
		<View style={styles.container}>
			{!error ? null :
				<View style={{backgroundColor: '#800011', width: '100%', alignItems: 'center', marginBottom: 2}}>
					<Text style={{color: "#fff", fontSize: 18, margin: 5}}>{"Error: " + error}</Text>
				</View>
			}
			
			<HeaderText user={myName} score={myScore} code={gd && gd.game ? gd.game.code : ""} />
			
			<PlayersDisplay players={!gd || !gd.game || !gd.game.q1 ? null : gd.p} localId={!gd ? null : gd.id} scores={scores} />
			
			<LoginComponent 
				disabled={gd && gd.game && gd.game.code.length > 0}
				onClickCreate={(pname)=> handleStart(pname,null)} 
				onClickJoin={(pname, code)=> handleStart(pname,code)}
				error={error}
			/> 
			
			<LobbyView 
				data={gd} 
				disabled={!gd || !gd.game || !(gd.game.q1 !== undefined && gd.game.q1.length == 0)} 
				onStart={() => handleSubmission(null,null,null) }
			/>
			
			<ResponseView
				disabled={!gd || !gd.game || gd.game.timerResp <= 0 || !gd.game.q1}
				count={responsesPerPlayer}
				question={latestQuestion}
				time={!gd || !gd.game ? 0 : gd.game.timerResp}
				onSubmit={(resp, callback)=>{ 
					handleSubmission(null, resp, (succ) => callback(succ != 0));
				}}
			/>
			
			<VoteForm disabled={!gd || !gd.game || gd.game.timerResp > 0 || gd.game.timerVote <= 0 || (gd.entries && gd.entries.length <= 0)}
				choices={currentChoices}
				remainingCount={votePairs.length}
				totalCount={roundEntryCount <= 1 ? null : ((roundEntryCount * (roundEntryCount - 1)) / 2)}
				prompt={latestQuestion}
				time={!gd || !gd.game ? 0 : gd.game.timerVote}
				onChoose={handleVote}
			/>
			
			<ResultsDisplay gamedata={gd} scores={scores} disabled={!gd || !gd.game || gd.game.timerVote > 0} time={timeSinceQuestion} />
			
			<HowToPlayControl />
			

			{gd && gd.game 
			? (<View style={{margin:50, width: 120}}>
				<Text>Press this button to quit the game:</Text>
				<Button title="Reload" onPress={() => {
					if(false){
						NativeModules.DevSettings.reload(); 
						return;
					}				
					if(updTO > 0) clearTimeout(updTO);
					setScores({});
					setGd({});
					setTimeout(()=>{
						if(updTO > 0) clearTimeout(updTO);
						setScores({});
						setGd({});
					}, 700);
				}} />
			</View>) 
			: null}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
	},
});

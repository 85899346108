import React from 'react';

function getUrl(realUrl) {
	const hasLoc = window && window.location && true;
	const needsHaxx = (hasLoc && window.location.hostname) ? (window.location.hostname.search("localhost") >= 0) : false;
	const prot = (hasLoc && !needsHaxx) ? (window.location.protocol) : "https:";
	let ret = "";
	const serverStub = "v0t3.com/_voter/";
	if(needsHaxx) ret += prot + "//cors-anywhere.herokuapp.com/";
	ret += prot + "//" + serverStub + realUrl;
	//console.log(ret); 
	return ret;
}


const Request = function(srcUrl, callback){
	const url = getUrl(srcUrl);
	let r = "none";
	fetch(url)
		.then((response) => { /*console.log("response from " + srcUrl); console.log(response); */ return (r=response).json()})
		.then((json) => {
			//console.log(json);
			callback(json);
		})
		.catch((error) => {
			console.log("error! "+error+",\n"+JSON.stringify(r));
			callback(error);
		})
		.finally(()=>{		
		});
};

export default Request;

import React, {useState} from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View } from 'react-native';
import {UniBar} from './UniBar';

export function ResultsDisplay(props) {
	const gd = props.gamedata;
	const scores = props.scores;
	const disable = !gd || !gd.results || !!props.disabled;
	if(disable)
		return null;
	const qNum = gd.game.q3 ? 3 : (gd.game.q2 ? 2 : (gd.game.q1 ? 1 : 0));
	if(qNum <= 0 || gd.results.length < qNum)
		return null;
	
	const knownIds = {};
	function getNameForId(id) {
		if(id == "total") return "All";
		if(!knownIds[id]) 
			for(let i=0; i<gd.p.length; i++) 
				if(gd.p[i].userid == id) {
					knownIds[id] = gd.p[i].name;
					break;
				}
		return knownIds[id];		
	}
	
	let entryOwners = {};
	for(let i=0; i<gd.entries[qNum - 1].length; i++)
		entryOwners[gd.entries[qNum - 1][i].resp] = gd.entries[qNum - 1][i].userid;
	
	const resultSet = gd.results[qNum-1];
	const resultKeys = [...Object.keys(resultSet)];
	resultKeys.sort((a,b) => {
		// total is always first
		if(a == "total") return ((b == "total") ? 0 : -1);
		if(b == "total") return 1;
		// my id is always second
		if(a == gd.id) return ((b == gd.id) ? 0 : -1);
		if(b == gd.id) return 1;
		// others are sorted alphabetically, i guess (should be score - todo!)
		return (getNameForId(a) == getNameForId(b)) ? 0 : ((getNameForId(a) < getNameForId(b)) ? -1 : 1);
	});
	const resultLists = [];
	
	for(let i=0; i<resultKeys.length; i++) {
		resultLists[i] = (
			<ResultList 
				title={getNameForId(resultKeys[i]) + " votes"} 
				entryUids={entryOwners }
				uid={resultKeys[i] == "total" ? gd.id : resultKeys[i]}
				data={resultSet[resultKeys[i]]} 
				scores={!props.scores ? null : ((!props.scores[resultKeys[i]] || props.scores[resultKeys[i]].length <= (qNum-1)) ? null : props.scores[resultKeys[i]][qNum-1])}
			/>
		);
	}
	
	return (
		<View style={styles.container}>
			<UniBar 
				width='100%'
				height={25}
				fill={1 - (props.time - 180) / 30} 
				label="Please wait..." 
			/>
			<Text style={styles.bighead}>Voting Results:</Text>
			<View style={styles.resultContainer}>
				{resultLists}
			</View>
		</View>
	);
	
}

export function ResultList(props) {
		
	const renderResult = (item) => {
		const dat = item.item;
		return (<VoteResult highlight={dat.hl} name={dat.r} win={dat.w} loss={dat.l} />);
	};
	
	const dat = props.data;
	if(!dat || !dat.length)
		return <View/>;

	for(var i=0;i<dat.length;i++) {
		dat[i].key = i + "";
		dat[i].hl = (!props.uid || !props.entryUids ? false : (props.entryUids[dat[i].r] == props.uid));
	}

	const ansScore = !props.scores ? 0 : props.scores.answers;
	const voteScore = !props.scores ? 0 : props.scores.votes;
	const bonScore = !props.scores ? 0 : props.scores.bonus;
	const totalScore = ansScore + voteScore + bonScore;
	
	return (
		<View style={styles.resultList}>			
			<Text style={styles.importantText}>{props.title}</Text>
			<View style={styles.divider}/>
			<FlatList data={dat} renderItem={renderResult} />
			{!props.scores ? null : <Text style={{fontSize: 9}}>{ansScore + " from Answers\n" + voteScore + " from Votes\n" + bonScore+" from Bonus"}</Text>}
			{!props.scores ? null : <Text>{totalScore} pts</Text>}
		</View>
	);
}

export function VoteResult(props) {
	const win = parseInt(props.win);
	const loss = parseInt(props.loss);
	const pct = Math.floor((1000.0 * win / (loss + win))) / 10;
	const sty = !props.highlight ? styles.smallText : StyleSheet.compose(styles.smallText, {color: 'red'});
	return (
	<View>
		<Text style={sty}>{props.name}</Text>
		<View style={styles.barBox}>
			<View style={styles.outerBar}>
				<View style={{width: (pct + "%"), height: "100%", backgroundColor: '#3d3'}} />
			</View>	
			<Text style={styles.tinyText}>{win}/{win+loss}</Text>
		</View>
	</View>
	);
}

const styles = StyleSheet.create({
	mainCont:{
		height: "100%",
		width: "100%"
	},
   container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  resultContainer: {
	flexDirection: 'row',
	flexWrap: 'wrap'
  },
  formCont: {
	flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
	width: 200
  },
  formArea: {
	  flex: 1, 
	  flexShrink: 0, 
	  flexGrow: 0, 
	  flexBasis: 150, 
	  flexDirection: 'row'
  },
  bighead: {
	  fontSize: 26
  },
  divider:{
	  height: 2,
	  marginVertical: 3,
	  width: "40%",
	  backgroundColor: "#444"
  },
  importantText: {
	  fontSize: 16
  },
  spacey: {
	margin: 20  
  },
  resultList: {
	margin: 5,
	width: 98,
  },
  fixToText: {
	width: "90%",
	flex: 1,	
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  barBox: {
	  flexDirection: 'row'
  },
  outerBar: {
	width: 75,
	height: 10,
	borderWidth: 1,
	marginRight: 3,
	backgroundColor: '#eaa'
  },
  smallText: {
	  fontSize: 11,
	  fontWeight: 'bold'
  },
  tinyText: {
	  fontSize: 7
  }
  
});
import React from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View, Animated, Easing } from 'react-native';


export function HeaderText(props){

	const title = props.title || "V0T3.com";
	const name = (props.user || "");
	const score = (!name || props.score === undefined) ? "" : (props.score || 0);
	const code = props.code || "";

	function calcTotalScore(scoreObj) {
		if(!scoreObj || !scoreObj.length)
			return 0;
		let tot = 0;
		for(let i=0; i<scoreObj.length; i++)
			if(!!scoreObj[i])
			{
				tot += scoreObj[i].answers;
				tot += scoreObj[i].votes;
				tot += scoreObj[i].bonus;
			}
		return tot;
	}
	
	return (
		<View style={styles.cont}>
			<View style={styles.bgGraphics}>
				<View style={styles.boxTL}><Text style={styles.starText}>★★</Text></View>
				<View style={styles.stripeRed} />
				<View style={styles.stripeWhite} />
				<View style={styles.stripeRed} />
				<View style={styles.stripeWhite} />
				<View style={styles.stripeRed} />
				<View style={styles.boxBR}><Text style={styles.starText}>★★</Text></View>
			</View>
			<View style={styles.subCont}>
				<Text style={styles.titleText}>{title + (code ? (" - " + code) : "")}</Text>
				<View style={{flexDirection: 'row'}}>
					<Text style={styles.nameText}>{name ? (name + ": ") : ""}</Text>
					<Text style={styles.nameText}>{score === "" ? "" : (" " + calcTotalScore(score) + " pts")}</Text>
				</View>
			</View>
		</View>	
	);
}

const HEADER_HEIGHT = 75;
const STRIPE_SIZE = Math.floor(HEADER_HEIGHT / 5);
const BOX_HEIGHT = STRIPE_SIZE * 3;
const BOX_WIDTH = BOX_HEIGHT * 1.33;

const styles = StyleSheet.create({
	cont: {
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginBottom: 10
	},
	subCont: {
		zIndex: 1,
		minHeight: HEADER_HEIGHT,
		alignItems: 'center'
	},
	titleText: {
		fontSize: 32, 
		marginTop: -6,
		marginBottom: 3,
		fontFamily: 'roboto-mono'
	},
	nameText: {
		fontSize: 18
	},
	bgGraphics: {
		height: HEADER_HEIGHT,
		marginTop: 0,
		marginBottom: -HEADER_HEIGHT,
		width: '100%'
	},
	starText: {
		color: '#f7f7ff',
		fontSize: 30
	},
	boxTL: {
		alignSelf: 'flex-start',
		backgroundColor: '#ededff',
		width: BOX_WIDTH,
		height: BOX_HEIGHT,
		marginBottom: -BOX_HEIGHT,
		zIndex: -1,
		alignItems: 'center',
	},
	boxBR: {
		alignSelf: 'flex-end',
		backgroundColor: '#ededff',
		width: BOX_WIDTH,
		height: BOX_HEIGHT,
		marginTop: -BOX_HEIGHT,
		zIndex: -1,
		alignItems: 'center',
	},
	stripeRed: {
		backgroundColor: '#fff0f2',
		width: '100%',
		height: STRIPE_SIZE,
		zIndex: -2
	},
	stripeWhite: {
		backgroundColor: '#fff',
		width: '100%',
		height: STRIPE_SIZE,
		zIndex: -2
	},
});

import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

export function HowToPlayControl(props) {
	const [isShowing, setIsShowing] = useState(false);

	
	
	return (
		<View style={styles.container}>
			<View>
				<TouchableOpacity onPress={() => setIsShowing(!isShowing)}>
					<Text style={styles.linkText}>
						{!isShowing ? "How to play? What is this game?" : "Click this again to hide the rules"}
					</Text>
				</TouchableOpacity>
			</View>
			{!isShowing
			? null
			:
			<View style={styles.content}>
				<Text style={styles.titleText}>What is V0T3 Dot Com?</Text>
				<View style={styles.sectText}>
				  <Text>
					<Text>This is a </Text><Text style={styles.boldText}>party game</Text><Text>, played with 2 to 12 friends. </Text>
					<Text>You will </Text><Text style={styles.boldText}>write answers</Text><Text> to questions, then </Text><Text style={styles.boldText}>vote on pairs</Text><Text> of responses. </Text>
					<Text>This creates an </Text><Text style={styles.boldText}>ordered ranking</Text><Text> of answers. </Text>
					<Text>You get points for </Text><Text style={styles.boldText}>your answers' ranking</Text><Text>, and for your ranking </Text><Text style={styles.boldText}>matching the group totals. </Text>
					<Text>After 3 Rounds, the player with the most points wins!</Text>
				  </Text>
				</View>
				<Text style={styles.titleText}>Other Info</Text>
				<View style={styles.sectText}>
				  <Text>
					This game is in super-duper secret alpha. Please do not close your browser or let your phone shut off, as you may get kicked from the game. I am working hard to make this game better, so stick with us please!
				  </Text>
				</View>				
			</View>
			}
		</View>
	);

}
const styles = StyleSheet.create({
	container:{
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		marginTop: 20,
		marginBottom: 10
	},
	content: {
		marginHorizontal: 20,
		maxWidth: 800,
	},
	linkText: {
		color: '#8e8cff',
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: 8,
		paddingTop: 4,
		paddingBottom: 6,
		paddingHorizontal: 9,
		borderWidth: 1,
		borderColor: '#8e8cff'
	},
	titleText: {
		fontFamily: 'roboto-mono',
		fontWeight: 'bold',
		fontSize: 18
	},
	sectText: {
		marginBottom: 5
	},
	boldText: {
		fontWeight: 'bold',
		color: '#400008'
	}
});
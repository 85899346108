import React, {useState} from 'react';
import { StyleSheet, ImageBackground, FlatList, TextInput, Button, Text, View, Animated, Easing } from 'react-native';


export function LoginComponent(props)
{
	const [pname, setPname] = useState("");
	const [code, setCode] = useState("");
	const fadeAnimName = React.useRef(new Animated.Value(0)).current;
	const fadeAnimCode = React.useRef(new Animated.Value(0)).current;

	const boxInterpolationName =  fadeAnimName.interpolate({
		inputRange: [0, 0.7, 1],
		outputRange:["rgb(255,255,255)" , "rgb(255,200,200)", "rgb(255,50,50)"]
	});	
	const boxInterpolationCode =  fadeAnimCode.interpolate({
		inputRange: [0, 0.7, 1],
		outputRange:["rgb(255,255,255)" , "rgb(255,200,200)", "rgb(255,50,50)"]
	});	
	const doFadeAnim = (anim) => {
		anim.setValue(1);
		Animated.timing(anim, {
			fromValue: 1,
			toValue: 0,
			duration: 1200
		}).start();
	};
	
	if(props.disabled && code.length > 0) setCode("");
	
	if(code.length > 5) setCode(code.substring(0, 5));
	else if(code.length > 0 && code != code.toUpperCase()) setCode(code.toUpperCase());
	
	return props.disabled ? null : (
	<View>
		<Text style={styles.headerText}>Player Name</Text>
		<Animated.View style={{backgroundColor: boxInterpolationName}}>
			<TextInput	
				style={styles.inputBox}
				placeholder="Enter your name"
				onChangeText={txt =>{ setPname(txt); if(txt.length === 0) doFadeAnim(fadeAnimName); }}
				defaultValue={pname}
			/>
		</Animated.View>
		<View style={styles.spacer} />
		<View style={styles.spacer} />
		
		{pname.length < 3 ? null :
		<View>
			<Text style={styles.headerText}>Enter Code to Join a Game</Text>
			<Animated.View style={{backgroundColor: boxInterpolationCode}}>
				<TextInput	
					style={styles.inputBox}
					placeholder="XXXX" 
					value={code} 
					onChangeText={txt => { setCode(txt); }}
				/>
			</Animated.View>
			<Button title="Join Game" 
				disabled={code.length < 4}
				onPress={()=>{
					if(pname.length < 3 || code.length < 4) {
						if(pname.length < 3) doFadeAnim(fadeAnimName);
						if(code.length < 4) doFadeAnim(fadeAnimCode);	
					}
					else props.onClickJoin(pname,code);
			}}/>		
			<View style={styles.spacer} />
			<Text style={styles.headerText}>OR</Text>			
			<View style={styles.spacer} />
			<Text style={styles.headerText}>Start a New Game</Text>
			<Button title="Create Game" 
				disabled={code.length > 0}
				onPress={()=>{
					if(pname.length < 3) doFadeAnim(fadeAnimName);		
					else props.onClickCreate(pname);
			}}/>

			<View style={styles.spacer} />
		</View>
		}
	</View>
	);
	
}



const styles = StyleSheet.create({

	spacer: {
		width: 12,
		height: 12,
	},
	inputBox: {
		fontSize: 20,
		height: 40,
		borderWidth: 1,
		padding: 5,
		marginBottom: 3
	},
	headerText: {
		fontSize: 16,
		fontWeight: 'bold',
		color: '#400008',
		margin: 4
	}
  
});